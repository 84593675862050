import React, { useContext, useState } from "react";
import "../../assets/style/globalStyle.scss";
import {
    updatePartnerById,
    deletePartner,
} from "../../services/partnerService";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import {
    GlobalStateContext,
    GlobalDispatchContext,
} from "../../context/GlobalContextProvider";
import ModalCard from "../../components/modalCard";

import ImagePicker from "../../components/imagePicker";
import LogoPicker from "../../components/logoPicker";

import { Formik } from "formik";
import * as Yup from "yup";

const paragraphStyle = {
    marginBottom: "10px",
    fontWeight: "lighter",
  };

const UpdatePartnerSchema = Yup.object().shape({
    updatedName: Yup.string().required("Veuillez entrer le nom de la société"),
    updatedPartnerType: Yup.string().required("Veuillez choisir la nature du partenaire"),
    updatedPhoneNumber: Yup.string().matches(/^[0-9]+$/, "Caractères invalides"),
    updatedEmail: Yup.string().email("Email invalide").required("Veuillez entrer un email de contact"),
    updatedContactName: Yup.string().required("Veuillez entrer le nom du contact de la société"),
    updatedStreetNumber: Yup.string().required("Veuillez entrer le numéro de rue"),
    updatedStreetName: Yup.string().required("Veuillez entrer le nom de rue"),
    updatedCity: Yup.string().required("Veuillez entrer le nom de la ville"),
});

const imgContainerStyles = {
    color: "#232129",
    padding: "96px",
    fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

const UpdatePartnerPage = () => {
    const GoToModal = async ({ modalType, showModal }) => {
        dispatch({
            type: "show_modal",
            payload: showModal,
            modalType: modalType,
        });
    };

    const dispatch = useContext(GlobalDispatchContext);
    const state = useContext(GlobalStateContext);

    const [isAddressCorrect, setisAddressCorrect] = useState(true)

    if (!state) {
        return (
            <div className="container is-fullhd" style={{ width: "880px" }}>
                <h1>Pas de partenaire </h1>
            </div>
        );
    }

    const { _id, logo, image, name, partnerType, url, phoneNumber, email, contactName, instagram, facebook, twitter, snapchat, streetNumber, streetName, city, description } =
        state.partner;

    return (
        <div className="box">

            <div className={imgContainerStyles}>
                <Carousel dynamicHeight={true}>
                    {state.partner.image.map((item, index) => (
                        <figure key={`image-` + index}>
                            <img
                                src={
                                    `https://res.cloudinary.com/ball-n-connect/image/upload/v1/partner_image/` +
                                    item
                                }
                                alt=""
                            ></img>
                        </figure>
                    ))}
                </Carousel>
            </div>
            <Formik
                initialValues={{
                    updatedName: name,
                    updatedPartnerType: partnerType,
                    updatedContactName: contactName,
                    updatedUrl: url,
                    updatedPhoneNumber: phoneNumber,
                    updatedEmail: email,
                    updatedInstagram: instagram,
                    updatedFacebook: facebook,
                    updatedTwitter: twitter,
                    updatedSnapchat: snapchat,
                    updatedStreetNumber: streetNumber,
                    updatedStreetName: streetName,
                    updatedCity: city,
                    updatedDescription: description,
                }}
                validationSchema={UpdatePartnerSchema}
                onSubmit={async (values) => {
                    try {
                        const newPartner = await updatePartnerById({
                            partnerId: _id,
                            name: values.updatedName,
                            partnerType: values.updatedPartnerType,
                            contactName: values.updatedContactName,
                            url: values.updatedUrl,
                            phoneNumber: values.updatedPhoneNumber,
                            email: values.updatedEmail,
                            instagram: values.updatedInstagram,
                            facebook: values.updatedFacebook,
                            twitter: values.updatedTwitter,
                            snapchat: values.updatedSnapchat,
                            streetNumber: values.updatedStreetNumber,
                            streetName: values.updatedStreetName,
                            city: values.updatedCity,
                            description: values.updatedDescription,
                        });

                        if (newPartner.isAddressCorrect === 'wrong') {
                            return setisAddressCorrect(false)
                        }
                    } catch (error) {
                        console.log(error);
                    }

                }}
            >
                {({
                    setFieldValue,
                    handleChange,
                    handleSubmit,
                    values,
                    errors,
                    touched,
                }) => (
                    <>
                        <div className="card-content">
                            <label className="label">
                                Images
                                <div className="field is-flex is-justify-content-space-between mt-2">
                                    <ImagePicker
                                        imageNumber={1}
                                        selectedId={_id}
                                        imgLink={
                                            image[0]
                                                ? `https://res.cloudinary.com/ball-n-connect/image/upload/partner_image/${_id}-1.jpg`
                                                : null
                                        }
                                        type="partner"
                                    />
                                    <ImagePicker
                                        imageNumber={2}
                                        selectedId={_id}
                                        imgLink={
                                            image[1]
                                                ? `https://res.cloudinary.com/ball-n-connect/image/upload/partner_image/${_id}-2.jpg`
                                                : null
                                        }
                                        type="partner"
                                    />
                                    <ImagePicker
                                        imageNumber={3}
                                        selectedId={_id}
                                        imgLink={
                                            image[2]
                                                ? `https://res.cloudinary.com/ball-n-connect/image/upload/partner_image/${_id}-3.jpg`
                                                : null
                                        }
                                        type="partner"
                                    />
                                </div>
                            </label>
                        </div>
                        <div className="card-content">
                            <label className="label">
                                Logo
                                <div className="field is-flex is-justify-content-space-between mt-2">
                                    <LogoPicker
                                        imageNumber={0}
                                        selectedId={_id}
                                        imgLink={
                                            logo
                                                ? `https://res.cloudinary.com/ball-n-connect/image/v1/upload/partner_image/${_id}.png`
                                                : null
                                        }
                                        type="partner"
                                    />
                                </div>
                            </label>
                        </div>
                        <hr />
                        <br />
                        <div className="columns is-centered">
                            <div className="column">
                                <div className="field">
                                    <label className="label">
                                        Nom du partenaire *
                                        <input
                                            className="input"
                                            type="text"
                                            placeholder={name}
                                            value={values.updatedName}
                                            onChange={(e) =>
                                                setFieldValue("updatedPartnerName", e.target.value)
                                            }
                                        />
                                    </label>
                                </div>
                            </div>
                            {errors.updatedName && touched.updatedName && (
                                <p style={{ color: "#cc0000" }}>{errors.updatedName}</p>
                            )}
                            <div className="column-is-centered mx-3">
                                <label className="label">
                                    Nature du partenaire *
                                    <br />
                                    <p style={paragraphStyle}>
                                        <i>valeur sélectionnée par l'admin :</i> <b>{partnerType}</b>
                                    </p>
                                    <div
                                        className="select"
                                        value={values.updatedPartnerType}
                                        onChange={(e) => setFieldValue("updatedPartnerType", e.target.value)}
                                    >
                                        <select>
                                            <option value="">Modifier la nature du partenaire</option>
                                            <option value="Club">Club</option>
                                            <option value="Marque">Marque</option>
                                            <option value="Shop">Shop</option>
                                            <option value="Complexe sportif">Complexe sportif</option>
                                            <option value="Mairie">Mairie</option>
                                            <option value="Association">Association</option>
                                        </select>
                                    </div>
                                </label>
                                {errors.updatedPartnerType && touched.updatedPartnerType && (
                                    <p style={{ color: "#cc0000" }}>{errors.updatedPartnerType}</p>
                                )}
                            </div>
                            <div className="column ">
                                <div className="field">
                                    <label className="label">
                                        URL
                                        <input
                                            className="input"
                                            type="url"
                                            placeholder={url || "URL du partenaire"}
                                            value={values.updatedUrl}
                                            onChange={(e) => setFieldValue("updatedUrl", e.target.value)}
                                        />
                                    </label>
                                </div>
                            </div>

                        </div>

                        <div className="columns is-centered">
                            <div className="column ">
                                <div className="field">
                                    <label className="label">
                                        Nom/prénom du contact *
                                        <input
                                            className="input"
                                            type="text"
                                            placeholder={contactName}
                                            value={values.updatedContactName}
                                            onChange={(e) =>
                                                setFieldValue("updatedContactName", e.target.value)
                                            }
                                        />
                                    </label>
                                </div>
                                {errors.updatedContactName && touched.updatedContactName && (
                                    <p style={{ color: "#cc0000" }}>{errors.updatedContactName}</p>
                                )}
                            </div>

                            <div className="column ">
                                <div className="field">
                                    <label className="label">
                                        Numéro de téléphone
                                        <input
                                            className="input"
                                            type="tel"
                                            placeholder={phoneNumber || "Numéro de téléphone du partenaire"}
                                            value={values.updatedPhoneNumber}
                                            onChange={(e) =>
                                                setFieldValue("updatedPhoneNumber", e.target.value)
                                            }
                                        />
                                    </label>
                                </div>
                                {errors.updatedPhoneNumber && touched.updatedPhoneNumber && (
                                    <p style={{ color: "#cc0000" }}>{errors.updatedPhoneNumber}</p>
                                )}
                            </div>
                            <div className="column ">
                                <div className="field">
                                    <label className="label">
                                        Email *
                                        <input
                                            className="input"
                                            type="email"
                                            placeholder={email}
                                            value={values.updatedEmail}
                                            onChange={(e) => setFieldValue("updatedEmail", e.target.value)}
                                        />
                                    </label>
                                </div>
                                {errors.updatedEmail && touched.updatedEmail && (
                                    <p style={{ color: "#cc0000" }}>{errors.updatedEmail}</p>
                                )}
                            </div>
                        </div>
                        <div className="columns is-centered">
                            <div className="column ">
                                <div className="field">
                                    <label className="label">
                                        Instagram
                                        <input
                                            className="input"
                                            type="text"
                                            placeholder={instagram || "Compte Instagram du partenaire"}
                                            value={values.updatedInstagram}
                                            onChange={(e) =>
                                                setFieldValue("updatedInstagram", e.target.value)
                                            }
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="column ">
                                <div className="field">
                                    <label className="label">
                                        Facebook
                                        <input
                                            className="input"
                                            type="tel"
                                            placeholder={facebook || "Compte Facebook du partenaire"}
                                            value={values.updatedFacebook}
                                            onChange={(e) =>
                                                setFieldValue("updatedFacebook", e.target.value)
                                            }
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="columns is-centered">
                            <div className="column ">
                                <div className="field">
                                    <label className="label">
                                        Twitter
                                        <input
                                            className="input"
                                            type="text"
                                            placeholder={twitter || "Compte twitter du partenaire"}
                                            value={values.updatedTwitter}
                                            onChange={(e) =>
                                                setFieldValue("updatedTwitter", e.target.value)
                                            }
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className="column ">
                                <div className="field">
                                    <label className="label">
                                        Snapchat
                                        <input
                                            className="input"
                                            type="tel"
                                            placeholder={snapchat || "Compte Snapchat du partenaire"}
                                            value={values.updatedSnapchat}
                                            onChange={(e) =>
                                                setFieldValue("updatedSnapchat", e.target.value)
                                            }
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <br />
                        <hr />
                        {!isAddressCorrect && (
                            <p style={{ color: "#cc0000", textAlign: "center", marginBottom: 10 }}>L'adresse est incorrect, modifiez-là</p>
                        )}
                        <div className="columns is-centered">
                            <div className="column is-one-quarter">
                                <label className="label">
                                    N° de Rue *
                                    <input
                                        className="input"
                                        type="numeric"
                                        placeholder={streetNumber}
                                        value={values.updatedStreetNumber}
                                        onChange={(e) =>
                                            setFieldValue("updatedStreetNumber", e.target.value)
                                        }
                                    />
                                </label>
                                {errors.updatedStreetNumber && touched.updatedStreetNumber && (
                                    <p style={{ color: "#cc0000" }}>{errors.updatedStreetNumber}</p>
                                )}
                            </div>

                            <div className="column">
                                <label className="label">
                                    Nom de la rue *
                                    <input
                                        className="input"
                                        type="text"
                                        placeholder={streetName}
                                        value={values.updatedStreetName}
                                        onChange={(e) =>
                                            setFieldValue("updatedStreetName", e.target.value)
                                        }
                                    />
                                </label>
                                {errors.updatedStreetName && touched.updatedStreetName && (
                                    <p style={{ color: "#cc0000" }}>{errors.updatedStreetName}</p>
                                )}
                            </div>

                            <div className="column is-one-quarter">
                                <label className="label">
                                    Ville *
                                    <input
                                        className="input"
                                        type="text"
                                        placeholder={city}
                                        value={values.updatedCity}
                                        onChange={(e) => setFieldValue("updatedCity", e.target.value)}
                                    />
                                </label>
                                {errors.updatedCity && touched.updatedCity && (
                                    <p style={{ color: "#cc0000" }}>{errors.updatedCity}</p>
                                )}
                            </div>
                        </div>
                        <hr />
                        <br />
                        <div className="field">
                            <label className="label">
                                Description
                                <textarea
                                    className="textarea"
                                    placeholder={description || "Description"}
                                    value={values.updatedDescription}
                                    onChange={(e) => setFieldValue("updatedDescription", e.target.value)}
                                ></textarea>
                            </label>
                        </div>
                        <br />
                        <div className="columns">
                            <div className="column">
                                <button
                                    className="button is-primary is-outlined"
                                    type="submit"
                                    onClick={() => handleSubmit()}
                                >
                                    Enregistrer les modifications
                                </button>
                            </div>

                            <div className="column">
                                <button
                                    className="button is-danger is-outlined"
                                    onClick={() =>
                                        GoToModal({ modalType: "delete", showModal: true })
                                    }
                                >
                                    Supprimer le partenaire
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </Formik>
            <ModalCard
                isActiveClassName={state.showModal && state.modalType === "delete"}
                title="Supprimer"
                content="Voulez-vous supprimer ce partenaire ?"
                validateClick={() => {
                    deletePartner({ partnerId: _id });
                    dispatch({
                        type: "show_modal",
                        payload: false,
                        modalType: "",
                    });
                }}
            />
        </div>
    );
};

export default UpdatePartnerPage;
