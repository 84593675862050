import React, { useState } from "react";
import { addPartnerLogo } from "../services/partnerService";

const LogoPicker = ({ selectedId, imgLink, type }) => {
    const [image, setImage] = useState({
        data: null,
        fullscreen: false,
        loading: false,
    });



    const handleFileChange = (event) => {
        const { target } = event;
        const { files } = target;

        if (files && files[0]) {
            var reader = new FileReader();

            reader.onloadstart = () => setImage({ ...image, loading: true });

            reader.onload = async (event) => {
                const imgEventsBase64 = event.target.result;
                setImage({
                    ...image,
                    data: imgEventsBase64,
                    loading: false,
                });
                switch (type) {
                    // case "society":
                    //     addSocietyImg({
                    //         imgEventsBase64,
                    //         societyId: selectedId,
                    //     });
                    //     break;
                    // case "sponsor":
                    //     addSponsorImg({
                    //         imgEventsBase64,
                    //         sponsorId: selectedId,
                    //     });
                    //     break;
                    case "partner":
                        addPartnerLogo({
                            imgEventsBase64,
                            partnerId: selectedId,
                        });
                        break;

                    default:
                        break;
                }
            };
            reader.readAsDataURL(files[0]);
        }
    };

    const handlePreviewClick = () => {
        const { fullScreen } = image;

        if (!image.data) {
            return;
        }

        setImage({ ...image, fullScreen: !fullScreen });
    };

    return (
        <div className="file has-name is-boxed">
            <label className="file-label">
                <input
                    className="file-input"
                    type="file"
                    // accept=".png"
                    name="resume"
                    onChange={(e) => handleFileChange(e)}
                />
                <span className="file-cta">
                    <span className="file-icon">
                        <i className="fas fa-upload"></i>
                    </span>
                    <span role="button" tabIndex={0} className="file-label" onClick={() => handlePreviewClick()} onKeyDown={() => handlePreviewClick()}>
                        Choisir une image...
                    </span>
                </span>
                <span className="file-name" style={{ height: "100%" }}>
                    {image.data && <img src={image.data ? image.data : null} alt="" />}
                    {!image.data && imgLink && <img src={imgLink} alt="" />}
                </span>
            </label>
        </div>
    );
};

export default LogoPicker;
